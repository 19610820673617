<template>
  <section v-if="error" class="hero is-fullheight is-primary">
    <div class="hero-body is-justify-content-center">
      <div class="columns is-vcentered">
        <div class="column has-text-centered">
          <p class="subtitle">
            {{ error.message }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ErrorMessage",
  props: ["error"],
  async mounted() {
    this.$emit("ready", true);
  },
};
</script>
